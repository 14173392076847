import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef,Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AudioSegment } from '../_models/audio-segment.model';
import { Word } from '../_models/word.model';
import { TranscriptionsService } from '../_services/transcriptions.service';
import { faForward,faPlay, faPause, faStop, faDownload, faFileWord, faFileCode,faCircleXmark,faFileInvoice} from '@fortawesome/free-solid-svg-icons';
import { AudioTableService } from '../_services/audio-table.service';
import { saveAs } from 'file-saver';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import {TokenStorageService} from '../_services/token-storage.service';
import { RequestModalService } from '../_shared/request-modal.service';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { CloseScrollStrategy } from '@angular/cdk/overlay';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import api_path from '../../api-path-config'
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { AuthService } from '../_services/auth.service';
import { min } from 'lodash';



declare var $: any; 

export class Dictionary {
  items = {};
  constructor() {
    this.items = {};
  }
  public has(key) {
    return key in this.items;
  }
  public set(key,value) {
    this.items[key] = value;
  }
  public get(key) {
    return this.items[key];
  }
  public delete(key) {
    if( this.has(key) ){
      delete this.items[key]
      return true;
    }
    return false;
  }
}


@Component({
  selector: 'app-transcription',
  templateUrl: './transcription.component.html',
  styleUrls: ['./transcription.component.css',],
})
export class TranscriptionComponent implements OnInit{
  playbackSpeed:any;
  originalTextEdit:any;
  contentType: any;
  speakerToUpdate: any;
  currentJobJSON: any;
  segmentTranscriptionJSON:any;
  agent:any;
  currentSegmentInEdit: any;
  currentTextInEdit:string;
  isEditing = false;
  isSingleClick = true;
  downloadFormatList = ['docx','json','srt'];
  selectedDownloadFormat:string;
  faDocumentWord = faFileWord;
  faDocumentCode = faFileCode;
  faDocumentSubtitle = faFileInvoice;
  faCircleX = faCircleXmark;
  faForward = faForward;
  jobId: any;
  audioId: any;
  fileName: string;
  asrDomain: string;
  transcription: AudioSegment[];
  safeblobUrl: any;
  faPlay = faPlay;
  faPause = faPause;
  faStop = faStop;
  faDownload = faDownload;
  audio: any;
  currentTime: number = 0;
  speakers:any;
  playing = false;
  length:any;
  slidervalue: number = 0;
  sliderIsMoving: boolean = false;
  totalAudioLength: number = 0;
  colorMap: string[]=[	'#0072BD', 	'#D95319','#EDB120',	'#7E2F8E','#77AC30','#4DBEEE','#A2142F','#b4bcdc','#bc6e7e','#ab911b'];
  map = new Map<string, string>();
  progressBarWidth: string = '700px';
  speakerSegmentsDivision:string;
  isScrolling: boolean = false;
  checked_token_time:any ;
  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private messageService: MessageService, 
    private primengConfig: PrimeNGConfig,
    private transcriptionsService: TranscriptionsService,
    private route: ActivatedRoute,
    private requestModalService: RequestModalService,
    @Inject(DOCUMENT) private document: Document,
    private audioTableService : AudioTableService,
    private sanitizer: DomSanitizer,
    private tokenStorage: TokenStorageService,
    private router: Router
    ) { 
      this.jobId = this.route.snapshot.queryParamMap.get('jobId');
      this.audioId = this.route.snapshot.queryParamMap.get('audioId');
      this.fileName = this.route.snapshot.queryParamMap.get('fileName');
      this.asrDomain = this.route.snapshot.queryParamMap.get('asrDomain');
      this.length = this.route.snapshot.queryParamMap.get('length');
      this.transcription = [];
      this.audio = {};
    }

    ngOnInit(): void {
      //this.keepSwAlive(this.audioId);
      this.playbackSpeed = "1";
      this.primengConfig.ripple = true;
      this.contentType =null
      if (!!this.tokenStorage.getToken()){
        this.checked_token_time = new Date();
      //try {
      //this.audioTableService.checkSwVideoFile(this.audioId).subscribe({
      //  next: (data) => {
          
      //  },
      //  error: (data)=> {
      //    window.location.reload();
      //  }
      //}
      
      //);
      //}catch (e){
      //  console.log(e)
      //}

      if (!(window.navigator.userAgent.toLowerCase().indexOf('firefox') < 0))  this.translate.get('transcription.warningFirefox').subscribe((res: string) => { let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1], sticky:true});});

      this.selectedDownloadFormat = 'docx';
      this.getTranscription(this.jobId);
      let width = this.document.getElementById("progress-bar").offsetWidth;
      width = width -19;
      this.progressBarWidth = width + 'px';
      this.playing = false;
      this.speakers = new Dictionary();
/*       this.audioTableService.getVideoFile(this.audioId).map(data => {const blob = new Blob([data.body], { 'type' : 'application/octet-stream' }); const blobUrl = URL.createObjectURL(blob); this.safeblobUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);}).subscribe({
        next: (dataFile: { body: any; }) => {
          const blob = new Blob([dataFile.body], { 'type' : 'application/octet-stream' });
          const link = document.createElement('a');
          const blobUrl = URL.createObjectURL(blob);
          this.safeblobUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
          $(document).ready( () => {
            this.audio= <HTMLAudioElement>document.getElementById("myaudio");
            if(this.audio != null) {
              this.audio.currentTime=0;
              this.currentTime = this.audio.currentTime;
              let width = this.document.getElementById("progress-bar").offsetWidth;
              width = width -19;
              this.progressBarWidth = width + 'px';
            }
          })
        },
        error: (err: any) => {
          
          console.log(err);
          return null;
        }
    }); */
    
    this.audioTableService.getVideoFile(this.audioId).subscribe({
      next: (data) => {
          this.contentType = data.headers.get("content-type");
        }

      }
    ); 
    

    
    this.safeblobUrl = api_path+'/mediastream/'+this.audioId+'/'+this.tokenStorage.getToken();

          $(document).ready( () => {
            this.audio= <HTMLAudioElement>document.getElementById("myaudio");
            if(this.audio != null) {
              this.audio.currentTime=0;
              this.currentTime = this.audio.currentTime;
              let width = this.document.getElementById("progress-bar").offsetWidth;
              width = width -19;
              this.progressBarWidth = width + 'px';
            }
          });
     }else{
      this.navigateToHome();
    }
  }


  playAudio(): void {
    
    $(document).ready( () => {
      var audio= <HTMLAudioElement>document.getElementById("myaudio");
      if(audio != null) {
        audio.play();
        //audio.playbackRate=2;
        this.playing = true;
      }
    })
  }

  changeSpeed(): void {
    var audio= <HTMLAudioElement>document.getElementById("myaudio");
      if(audio != null) {
        audio.playbackRate=Number(this.playbackSpeed);

      }
  }

  pauseAudio(): void {
    $(document).ready( () => {
      var audio= <HTMLAudioElement>document.getElementById("myaudio");
      if(audio != null) {
        audio.pause();
        this.playing = false;
      }
    })
  }

  stopAudio(): void {
    $(document).ready( () => {
      var audio= <HTMLAudioElement>document.getElementById("myaudio");
      if(audio != null) {
        audio.pause();
        this.playing = false;
        audio.currentTime=0;
      }
    })
  }

  getCurrentTime(): void {
    $(document).ready( () => {
      var audio= <HTMLAudioElement>document.getElementById("myaudio");
      if(audio != null) {
        console.log(audio.currentTime);
      }
    })
  }


  downloadAudio(): void {
    this.audioTableService.getAudioFile(this.audioId).subscribe({
      next: (dataFile: { body: any; }) => {
        const blob = new Blob([dataFile.body], { 'type' : 'application/octet-stream' });
        saveAs(blob, this.fileName);
      },
      error: (err: any) => {
        console.log(err);
        return null;
      }
    });
  }

  getTranscription(jobId: number): void {
    this.transcriptionsService.getTranscriptionJob(jobId).subscribe({
      next: (crtJob: { body: any; }) => {
        this.currentJobJSON=crtJob.body;
        let postProcess= false;
        let segments = crtJob.body.transcript.segments;
        if ('post-processed-transcription' in crtJob.body) {
          segments = crtJob.body['post-processed-transcription'].segments;
          postProcess= true;
        }
        
        let previousSegment: AudioSegment = {};
        let k =0;
        this.speakerSegmentsDivision = '';
        for (let i = 0; i < segments.length ; i++) { 
          if(true) {
            let audioSegment: AudioSegment = {};
            let wordsArray: Word[] = [];
            let crtAudioSegment = segments[i];
            
            
            audioSegment.segmentLength = crtAudioSegment['segment-length'];
            audioSegment.segmentStart = crtAudioSegment['segment-start'];
            audioSegment.segmentBand = crtAudioSegment['segment_band'];
            audioSegment.segmentType = crtAudioSegment['segment_type'];
            audioSegment.speakerGender = crtAudioSegment['speaker_gender'];
            audioSegment.speakerName = crtAudioSegment['speaker_name'];
            let speakerNgModel = crtAudioSegment['speaker_name'] + this.audioId;
            this.speakers.set(crtAudioSegment['speaker_name'],speakerNgModel);

            

            let words = [];
            if (postProcess){
              words = crtAudioSegment['result']['hypotheses']['word-alignment'];
            }else{
              words = crtAudioSegment['result']['hypotheses'][0]['word-alignment'];
            }
            
            for (var crtWord of words) { 
              let word: Word = {};
              word.word = crtWord['word'];
              word.confidence = crtWord['confidence'];
              word.length = crtWord['length'];
              word.start = crtWord['start'];
              if (word.confidence==-1){
                wordsArray.push(word);
              }else{
                word.word = " " +word.word;
                wordsArray.push(word);
              }
              
            }

            // Fix for empy segments (where no word is provided)
            if (wordsArray.length>0){
            // fix for text editor 
            wordsArray[0].word=wordsArray[0].word.trimStart();
            }else{
              let word: Word = {};
              word.word = "";
              word.confidence = 0;
              word.length = audioSegment.segmentLength;
              word.start = audioSegment.segmentStart;
              wordsArray.push(word);
            }

            audioSegment.wordsArray = wordsArray;
            
            if(i % 2 == 0) {
              audioSegment.leftAlignment = true;
            } else {
              audioSegment.leftAlignment = false;
            }
            if  (!this.map[audioSegment.speakerName]){
              this.map[audioSegment.speakerName] = this.colorMap[k];
              k=k+1;
             }
             if (k>9){
               k=0;
             }

            //  if (i ==0 && segments.length>1){
            //   let segmentProgressBarStart = 0;
            //   this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(this.map[audioSegment.speakerName],' ');
            //   this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarStart.toString(),'%,');
            //  }

            if (i ==0 && (segments.length-1)==0){
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat("linear-gradient(to right, ");
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(this.map[audioSegment.speakerName],' 0 100%)');
             
            }

            if (i==0 && (segments.length-1)>0){
              let segmentProgressBarStart = 0;
              let segmentProgressBarEnd = (audioSegment.segmentStart) / this.length * 100;
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat("linear-gradient(to right, ");
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(this.map[audioSegment.speakerName],' ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarStart.toString(),'% ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarEnd.toString(),'%');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(',transparent 0 100%),');
             }

             if (i>0 && i<segments.length-1){
              let segmentProgressBarStart = (previousSegment.segmentStart) / this.length * 100;
              let segmentProgressBarEnd = (audioSegment.segmentStart) / this.length * 100;
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat("linear-gradient(to right, transparent 0 ");
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarStart.toString(),'%, ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(this.map[previousSegment.speakerName],' ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarStart.toString(),'% ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarEnd.toString(),'%');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(',transparent 0 100%),');
             }

             

             if (i == segments.length-1 && segments.length>1){
              let segmentProgressBarStart = (previousSegment.segmentStart) / this.length * 100;
              let segmentProgressBarEnd = (audioSegment.segmentStart) / this.length * 100;
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat("linear-gradient(to right, transparent 0 ");
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarStart.toString(),'%, ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(this.map[previousSegment.speakerName],' ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarStart.toString(),'% ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarEnd.toString(),'%');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(',transparent 0 100%),');

              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat("linear-gradient(to right, transparent 0 ");
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarEnd.toString(),'%, ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(this.map[audioSegment.speakerName],' ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(segmentProgressBarEnd.toString(),'% ');
              this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat('100%)');
             
            }


            previousSegment = audioSegment;
             //this.speakerSegmentsDivision = "linear-gradient(to left, blue, blue 20%, red 20%, red 100%)"
            this.transcription.push(audioSegment);
          }
          
        }
        this.getTotalAudioLength();
        this.setProgressBarSegmentLength();
        //this.speakerSegmentsDivision = this.speakerSegmentsDivision.concat(')');
      },
      error: (err: any) => {
        
        console.log(err);
        return null;
      }
    });
  }

  checkTokenFromStorage(){
    if (!!!this.tokenStorage.getToken()){
      this.translate.get('autoLogoutMessage').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1], sticky:true});});
      this.tokenStorage.signOut();
      setTimeout(()=>{this.router.navigate(['/']);window.location.reload()},2000);
    }else{
    let current_time = new Date();
    let request_timeout = 10000;
    if ((current_time.getTime()-this.checked_token_time.getTime())>request_timeout){
      this.authService.info().subscribe({
        next: (data) => {

        },
        error:(data) => {
          this.translate.get('autoLogoutMessage').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1], sticky:true});});
          this.tokenStorage.signOut();
          setTimeout(()=>{this.router.navigate(['/']);window.location.reload()},2000);
        }
      })
      this.checked_token_time = current_time;
    }

    }
    
  }

 async keepSwAlive(audioId: any) {


    this.audioTableService.getVideoFile(this.audioId).subscribe({
      next: (data) => {
          this.contentType = data.headers.get("content-type");
          setTimeout(()=>{               
            this.keepSwAlive(audioId);
           }, 600);
        },
      error:(data) =>{
        this.translate.get('autoLogoutMessage').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1], sticky:true});});
        this.tokenStorage.signOut();
        this.router.navigate(['/']);
        window.location.reload();
        }
      }
    );

 

    
  }

  wordClick(word:any,startTime: any, duration: any, contentEditable: any): void {
    
    if (!this.isEditing){
        this.isSingleClick = true;
        setTimeout(()=>{
          
            if(this.isSingleClick ){
                 this.audio.currentTime=startTime;
                 var playPromise = this.audio.play();

                 if (playPromise !== undefined) {
                   playPromise.then(_ => {
                     this.playing = true;
                     this.currentTime = this.audio.currentTime;
                   })
                   .catch(error => {
                   });
                 }
                      }},300);
      }
  }

  wordDbClick(index_transcription,index_word:any){
    if (this.isEditing==false){
      this.currentSegmentInEdit = JSON.parse(JSON.stringify(this.transcription[index_transcription]));
    }
    this.isEditing = true;
    this.isSingleClick = false;
    //this.transcription[index_transcription].wordsArray[0].word=this.transcription[index_transcription].wordsArray[0].word.trimStart();
    let time_start = this.currentSegmentInEdit.wordsArray[index_word].start-1;
    if (time_start<0) time_start = 0;
    this.audio.currentTime=time_start;
    let playLength = 2; 
    
    var playPromise = this.audio.play();

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        this.playing = true;
        setTimeout(()=>{
            this.pauseAudio();
            this.playing = false;
        },1000*playLength);
        this.currentTime = this.audio.currentTime;
      })
      .catch(error => {
      });
    }



  }

  editingFinished(index_segment){
    
    this.currentTextInEdit = document.getElementById(index_segment.toString()).innerText;
    this.isEditing = false;
    if (this.originalTextEdit!=document.getElementById(index_segment.toString()).innerHTML){
    //try{
      
      let originalWordArray=[];
      for (let j=0; j< this.transcription[index_segment].wordsArray.length; j++){
        originalWordArray.push(this.transcription[index_segment].wordsArray[j].word)
      }
      

      // add space before puctuation 
      
      let rawCurrentTextInEdit =  this.currentTextInEdit;
      this.currentTextInEdit = this.currentTextInEdit.replaceAll(',', ' ,');
      this.currentTextInEdit = this.currentTextInEdit.replaceAll('.', ' .');
      this.currentTextInEdit = this.currentTextInEdit.replaceAll('?', ' ?');
      this.currentTextInEdit = this.currentTextInEdit.replaceAll('!', ' !');
      this.currentTextInEdit = this.currentTextInEdit.replaceAll('\n', '');
      let regex_date = new RegExp(/\d{1,2} \.\d{1,2} \.\d{2,4}/,'g');
      this.currentTextInEdit = this.currentTextInEdit.replaceAll(regex_date, match => match.replaceAll(" ",""));
      
      rawCurrentTextInEdit = rawCurrentTextInEdit.replaceAll('\n', '');
      rawCurrentTextInEdit = rawCurrentTextInEdit.replaceAll(regex_date, match => match.replaceAll(" ",""));
      
      

      // split text by space
      let editedWordArray = this.currentTextInEdit.split(" ");
      editedWordArray = editedWordArray.filter(item => item);

      if (editedWordArray.length>0){

          this.transcription[index_segment].wordsArray = this.generateEditedTimestamps(this.transcription[index_segment].wordsArray,editedWordArray)
          let segmentCurent = JSON.parse(JSON.stringify(this.transcription[index_segment]));
          this.transcription.splice(index_segment,1);
          this.transcription.splice(index_segment,0,segmentCurent);
          //let editedWordArray: Word[]=[];

        
          if (this.currentTextInEdit){
            let transcriptionJSON = JSON.parse(JSON.stringify(this.transcription));
              for (let j=0; j< transcriptionJSON[index_segment].wordsArray.length; j++){
                transcriptionJSON[index_segment].wordsArray[j].word = transcriptionJSON[index_segment].wordsArray[j].word.replaceAll("\n", "");
                transcriptionJSON[index_segment].wordsArray[j].word = transcriptionJSON[index_segment].wordsArray[j].word.trim();
              }
            

            if ('post-processed-transcription' in this.currentJobJSON) {
              //segments = crtJob.body['post-processed-transcription'].segments;
              this.currentJobJSON['post-processed-transcription'].segments[index_segment]['result']['hypotheses']['word-alignment']=transcriptionJSON[index_segment].wordsArray;
              this.currentJobJSON['post-processed-transcription'].segments[index_segment]['result']['hypotheses']['transcript'] =  rawCurrentTextInEdit;
            }else{
              this.currentJobJSON.transcript.segments[index_segment]['result']['hypotheses'][0]['word-alignment']=transcriptionJSON[index_segment].wordsArray;
              this.currentJobJSON.transcript.segments[index_segment]['result']['hypotheses'][0]['transcript'] = rawCurrentTextInEdit;
            }
            this.transcriptionsService.postTranscription(this.jobId,this.currentJobJSON).subscribe({
              next: () => {
                  this.translate.get('transcription.transcriptionSaved').subscribe((res: string) => { let message = res.split(":"); this.messageService.add({ severity:'success', summary: message[0], detail: message[1]});});
              },
              error: () =>{
                this.translate.get('transcription.transcriptionSaveError').subscribe((res: string) => {let message = res.split(":"); this.messageService.add({ severity:'error', summary: message[0], detail: message[1], sticky:true});});
              }
            });
            
          }
      }else{
        this.transcription.splice(index_segment,1);
        if ('post-processed-transcription' in this.currentJobJSON) {
          //segments = crtJob.body['post-processed-transcription'].segments;
          this.currentJobJSON['post-processed-transcription'].segments.splice(index_segment,1);
          
        }else{
          this.currentJobJSON.transcript.segments.splice(index_segment,1);
        }
        
        this.transcriptionsService.postTranscription(this.jobId,this.currentJobJSON).subscribe({
          next: () => {
              this.translate.get('transcription.transcriptionSaved').subscribe((res: string) => { let message = res.split(":"); this.messageService.add({ severity:'success', summary: message[0], detail: message[1]});});
          },
          error: () =>{
            this.translate.get('transcription.transcriptionSaveError').subscribe((res: string) => {let message = res.split(":"); this.messageService.add({ severity:'error', summary: message[0], detail: message[1], sticky:true});});
          }
        });
      }
    /*}
     catch (e){
      this.translate.get('transcription.transcriptionTextError').subscribe((res: string) => {let message = res.split(":"); this.messageService.add({ severity:'error', summary: message[0], detail: message[1], sticky:true});});
      setTimeout(()=>{window.location.reload()},2000);
    } */
    this.currentTextInEdit=null;
    this.currentSegmentInEdit=null;
    this.segmentTranscriptionJSON=null;
    

    }
  }


  onMouseDown(mouseEvent: MouseEvent) {
    // prevent text selection for dbl clicks.
    if (mouseEvent.detail > 1) mouseEvent.preventDefault();
  }
  
  disableCtrlKeys(event:KeyboardEvent){
    console.log(event)
    if (event.key=="s"&&event.ctrlKey){
      event.preventDefault();
      
    }
    //if (event.ctrlKey || event.metaKey){
    //  event.preventDefault();
    //}

  }

  saveOriginalTextEdit(index_segment){
    this.originalTextEdit = document.getElementById(index_segment.toString()).innerHTML;
  }

  updateSpeakerChanges(event:Event,index_segment){
    this.speakerToUpdate = (<HTMLInputElement>event.target).innerText;
}

  editingSpeakerFinished(event:any,index_segment){
    let oldSpeaker = JSON.parse(JSON.stringify(this.transcription[index_segment].speakerName));
    if (this.speakerToUpdate!=null){
      if(this.speakerToUpdate.length!=0){
        this.map.set(oldSpeaker,this.speakerToUpdate)
        this.map[this.speakerToUpdate] = this.map[oldSpeaker];
        for (let index_speaker=0;index_speaker<this.transcription.length;index_speaker++){
          if (this.transcription[index_speaker].speakerName == oldSpeaker){
            this.transcription[index_speaker].speakerName = this.speakerToUpdate;
            if ('post-processed-transcription' in this.currentJobJSON) {
              this.currentJobJSON['post-processed-transcription'].segments[index_speaker]['speaker_name']=this.speakerToUpdate;
              
            }else{
              this.currentJobJSON.transcript.segments[index_speaker]['speaker_name']=this.speakerToUpdate;
            }


          }
        }
        this.transcriptionsService.postTranscription(this.jobId,this.currentJobJSON).subscribe({
          next: () => {
            this.translate.get('transcription.transcriptionSaved').subscribe((res: string) => { let message = res.split(":"); this.messageService.add({ severity:'success', summary: message[0], detail: message[1]});});

          },
          error: () =>{
            this.translate.get('transcription.transcriptionSaveError').subscribe((res: string) => {let message = res.split(":"); this.messageService.add({ severity:'error', summary: message[0], detail: message[1], sticky:true});});
          }
        }); 
    }else{

      (<HTMLInputElement>event.target).innerText= oldSpeaker;
      this.translate.get('transcription.transcriptionEmptySpeaker').subscribe((res: string) => {let message = res.split(":"); this.messageService.add({ severity:'warn', summary: message[0], detail: message[1], sticky:true});});
    }
    this.speakerToUpdate = null;
  }
  }

  changeGender(index_segment,new_gender){
    let oldSpeaker = JSON.parse(JSON.stringify(this.transcription[index_segment].speakerName));
        for (let index_speaker=0;index_speaker<this.transcription.length;index_speaker++){
          if (this.transcription[index_speaker].speakerName == oldSpeaker){
            this.transcription[index_speaker].speakerGender = new_gender;
            if ('post-processed-transcription' in this.currentJobJSON) {
              this.currentJobJSON['post-processed-transcription'].segments[index_speaker]['speaker_gender']=new_gender;
              
            }else{
              this.currentJobJSON.transcript.segments[index_speaker]['speaker_gender']=new_gender;
            }
          }
        }
        this.transcriptionsService.postTranscription(this.jobId,this.currentJobJSON).subscribe({
          next: () => {
            this.translate.get('transcription.transcriptionSaved').subscribe((res: string) => { let message = res.split(":"); this.messageService.add({ severity:'success', summary: message[0], detail: message[1]});});

          },
          error: () =>{
            this.translate.get('transcription.transcriptionSaveError').subscribe((res: string) => {let message = res.split(":"); this.messageService.add({ severity:'error', summary: message[0], detail: message[1], sticky:true});});
          }
        }); 

  }

  updateCurrentTime(): void {
    
    this.currentTime = this.audio.currentTime;
    if(this.document.getElementById("word-indentifier") != null) {
      let scroll = this.document.getElementById("scroll-view").scrollTop;
      let word = this.document.getElementById("word-indentifier").offsetTop;
      
      if(((word > 200 && scroll > 300)||(word > 500)) && !this.isScrolling) {
       
       if((word-scroll)>700 || (word-scroll)<300){       
       this.document.getElementById("scroll-view").scrollTop = this.document.getElementById("word-indentifier").offsetTop - 499;
       }
      }
    }
    if(!this.sliderIsMoving) {
      this.slidervalue = this.currentTime / this.audio.duration * 100;
    }
    if(Math.floor(this.currentTime*1000) >= (Math.floor(this.length*1000))){ 
      this.currentTime = this.length;
      this.stopAudio();
      this.playing = false;
    };
  }

  generateRandomColorRgb(): string {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return "rgb(" + red + ", " + green + ", " + blue + ")";
  }

  trimNgModelSpeakerName(speakerNAmeWithAudioId: string) {
    const trimText = speakerNAmeWithAudioId.slice(0, -5)
    return trimText;
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }


  downloadTranscriptionFile(id:string, jobId: number, fileName: string, format:string ): void {
    this.audioTableService.getAudioTranscription(jobId,format).subscribe({
      next: (dataFile: { body: any; }) => {
        const blob = new Blob([dataFile.body], { 'type' : 'raw' });
        fileName = fileName.replace('.',"_");
        fileName = fileName.concat('.',format);
        saveAs(blob, fileName);
        this.closeModal(id);
      },
      error: (err: any) => {
        console.log(err);
        return null;
      }
    });
  }

  getTotalAudioLength(): void {
    this.totalAudioLength = 0;
    for (let i = 0; i < this.transcription.length ; i++) { 
      let segment = this.transcription[i];
      this.totalAudioLength = this.totalAudioLength + segment.segmentLength;
    }
  }

  setProgressBarSegmentLength(): void {
    let counter = 0;
    
    for (let i = 0; i < this.transcription.length ; i++) { 
      let segment = this.transcription[i];
      let segmentProgressBarLength = Math.round(segment.segmentLength / this.length * 100);
      if(segmentProgressBarLength == 0) segmentProgressBarLength = 1;
      counter = counter + segmentProgressBarLength;
      if(i == this.transcription.length) {
        if(counter == 100) {
          this.transcription[i].segmentProgressBarLength = segmentProgressBarLength;
        } else {
          segmentProgressBarLength = segmentProgressBarLength + 100 - counter;
          this.transcription[i].segmentProgressBarLength = segmentProgressBarLength;
        }
      } else {
        this.transcription[i].segmentProgressBarLength = segmentProgressBarLength;
      }
    }
    
  }

  sliderRelease(): void {
    if(this.slidervalue > 97) {
      this.document.getElementById("scroll-view").scrollTop = this.document.getElementById("inner-scroll").offsetHeight;
    }
    this.currentTime = this.slidervalue * this.audio.duration / 100;
    this.audio.currentTime=this.currentTime;
    this.playing = true;
    this.sliderIsMoving = false;
    this.audio.play();
  }

  sliderMove(): void {
    this.sliderIsMoving = true;
  }

  onScrollEvent(): void {
    if(this.document.getElementById("word-indentifier") != null) {
      let scroll = this.document.getElementById("scroll-view").scrollTop;
      let word = this.document.getElementById("word-indentifier").offsetTop;
      
      if(((word - scroll) > 700 ) || ((word - scroll) < 200)){
        this.isScrolling = true;
      } else {
        this.isScrolling = false;
      }
    }
  }


  openModal(id: string) {
        this.requestModalService.open(id);
        this.selectedDownloadFormat = 'docx';
  }

  closeModal(id: string) {
    this.requestModalService.close(id);
  }
  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
      this.messageService.clear('c');
  }




  processAscendingTimestamps(idx: number[]): number[] {
    
      for (let i = 0; i < idx.length - 1; i++) {
          if (idx[i] > idx[i + 1]) {
              idx[i + 1] = idx[i];
          }
      }
      return idx;
  }
  
  
  generateEditedTimestamps(original_word_array_with_timestamps: Word[], edited_word_array: string[]): Word[] {
      const original_word_array: string[] = [];
      for (let i = 0; i < original_word_array_with_timestamps.length; i++) {
          original_word_array[i] = original_word_array_with_timestamps[i].word;
      }
      const weight_similarity: number = 1;
      const weight_distance: number = 1;
      const [cost_total, cost_distance, cost_similarity] = this.computeCostMatrix(original_word_array, edited_word_array, weight_similarity, weight_distance);
      //let min_vals:number[] = [];
      let idx:number[]=[];
      
      for (let i = 0; i < cost_total.length; i++) {
        //min_vals.push(Math.min(...cost_total[i]));
        idx.push(cost_total[i].indexOf(Math.min(...cost_total[i])));
    }
    
 
      this.processAscendingTimestamps(idx);
      let time_k = this.findTimestampAdjustmentWindows(edited_word_array, idx);
      const edited_word_array_with_timestamps = this.adjustTimestamps(edited_word_array, original_word_array_with_timestamps, idx, time_k);
      //const edited_word_array_with_timestamps = []
      return edited_word_array_with_timestamps;
  }
  
  
  findTimestampAdjustmentWindows(edited_word_array: any[], idx: number[]): Map<number,number> {
      let i = 0;
      let time_k = new Map<number,number>();
      
      while (i < edited_word_array.length) {
          let j = i + 1;
          while (idx[j - 1] + 1 !== idx[j] && j < edited_word_array.length) {
              j++;
          }
          if (j !== i + 1) {
              time_k.set(i,j-1);
          }
          i = j;
      }
      return time_k;
  }
  
  EditDist(s1: string, s2: string, deletionCost: number = 1, insertionCost: number = 1, replacementCost: number = 1): number {
    const m = s1.length;
    const n = s2.length;
  
    const dp: number[][] = [];
  
    for (let i = 0; i <= m; i++) {
      dp[i] = [];
      for (let j = 0; j <= n; j++) {
        if (i === 0) {
          dp[i][j] = j;
        } else if (j === 0) {
          dp[i][j] = i;
        } else {
          dp[i][j] = 0;
        }
      }
    }
  
    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        if (s1[i - 1] === s2[j - 1]) {
          dp[i][j] = dp[i - 1][j - 1];
        } else {
          dp[i][j] = min([
            dp[i - 1][j] + deletionCost,
            dp[i][j - 1] + insertionCost,
            dp[i - 1][j - 1] + replacementCost
          ]);
        }
      }
    }
  
    return dp[m][n];
  }
  
  computeCostMatrix(original_word_array: string[], edited_word_array: string[], weight_similarity: number, weight_distance: number): [number[][], number[][], number[][]] {
      const cost_distance: number[][] = [];
      const cost_similarity: number[][] = [];
  
      for (let i = 0; i < edited_word_array.length; i++) {
          cost_distance[i] = [];
          cost_similarity[i] = [];
  
          for (let j = 0; j < original_word_array.length; j++) {
              cost_distance[i][j] = Math.abs(j - i);
              cost_similarity[i][j] = this.EditDist(original_word_array[j], edited_word_array[i]);
          }
      }
  
      let max_similarity = Math.max(...cost_similarity.flat());
      let max_distance = Math.max(...cost_distance.flat());

      if (max_similarity==0){
        max_similarity=1;
      }

      if (max_distance==0){
        max_distance=1;
      }
  
      for (let i = 0; i < edited_word_array.length; i++) {
          for (let j = 0; j < original_word_array.length; j++) {
              cost_similarity[i][j] /= max_similarity;
              cost_distance[i][j] /= max_distance;
          }
      }
  
      const cost_total: number[][] = [];
  
      for (let i = 0; i < edited_word_array.length; i++) {
          cost_total[i] = [];
  
          for (let j = 0; j < original_word_array.length; j++) {
              cost_total[i][j] = weight_distance * cost_distance[i][j] + weight_similarity * cost_similarity[i][j];
          }
      }
  
      return [cost_total, cost_distance, cost_similarity];
  }
  
  
  adjustTimestamps(edited_word_array: string[], original_word_array_with_timestamps: any[], idx: number[], time_k: Map<number,number>): any[] {
      let edited_word_array_with_timestamps: Word[] = [];
      if (time_k.size == 0) {
          for (let i = 0; i < edited_word_array.length; i++) {

            if (":,.!?;".includes(edited_word_array[i])){
              edited_word_array_with_timestamps[i] = {
                word: edited_word_array[i],
                confidence: -1,
                start: original_word_array_with_timestamps[idx[i]].start,
                length: original_word_array_with_timestamps[idx[i]].length
            };
            }else{
              //console.log("editat:"+edited_word_array[i]+";original:"+original_word_array_with_timestamps[idx[i]].word.trim())


              if (edited_word_array[i]===original_word_array_with_timestamps[idx[i]].word.trim()){
                  edited_word_array_with_timestamps[i] = {
                    word: " " + edited_word_array[i],
                    confidence: original_word_array_with_timestamps[idx[i]].confidence,
                    start: original_word_array_with_timestamps[idx[i]].start,
                    length: original_word_array_with_timestamps[idx[i]].length
                  };
              }else{
                  edited_word_array_with_timestamps[i] = {
                    word: " " + edited_word_array[i],
                    confidence: 1,
                    start: original_word_array_with_timestamps[idx[i]].start,
                    length: original_word_array_with_timestamps[idx[i]].length
                  };

              }


            }


          }
      } else {
        
      let i = 0;
      while (i < edited_word_array.length) {
        if (time_k.get(i) == null) {
              if (":,.!?;".includes(edited_word_array[i])){
                edited_word_array_with_timestamps[i] = {
                  word: edited_word_array[i],
                  confidence: -1,
                  start: original_word_array_with_timestamps[idx[i]].start,
                  length: original_word_array_with_timestamps[idx[i]].length
                }
              }else{
                if (edited_word_array[i]===original_word_array_with_timestamps[idx[i]].word.trim()){
                    edited_word_array_with_timestamps[i] = {
                      word: " " + edited_word_array[i],
                      confidence: original_word_array_with_timestamps[idx[i]].confidence,
                      start: original_word_array_with_timestamps[idx[i]].start,
                      length: original_word_array_with_timestamps[idx[i]].length
                    };
                }else{
                    edited_word_array_with_timestamps[i] = {
                      word: " " + edited_word_array[i],
                      confidence: 1,
                      start: original_word_array_with_timestamps[idx[i]].start,
                      length: original_word_array_with_timestamps[idx[i]].length
                    };

                }
              }
              i = i + 1;
        } else {

          let start = original_word_array_with_timestamps[idx[i]].start;
          let total_length = original_word_array_with_timestamps[idx[time_k.get(i)]].start - original_word_array_with_timestamps[idx[i]].start + original_word_array_with_timestamps[idx[time_k.get(i)]].length ;
          
          let num_char:number[] = [];
          let sum_char:number = 0;
          for (let j = i; j <= time_k.get(i); j++) {
              if (":,.!?;".includes(edited_word_array[j])){
                num_char.push(0);
              }else{
                num_char.push(edited_word_array[j].length);
              }
              sum_char=sum_char+edited_word_array[j].length;
          }
          let i_ch=0;
          for (let j = i; j <= time_k.get(i); j++) {
            if (":,.!?;".includes(edited_word_array[j])){
              edited_word_array_with_timestamps[j] = {
                word: edited_word_array[j],
                confidence: -1,
                start: start,
                length: 0
              }
            }else{
              edited_word_array_with_timestamps[j] = {
                word: " "+edited_word_array[j],
                confidence: 1,
                start: start,
                length: Math.round( (total_length* num_char[i_ch]/sum_char)*1000)/1000
              }
            }
            start=start+total_length* num_char[i_ch]/sum_char;
            start = Math.round(start*1000)/1000;
            i_ch = i_ch +1;
        }
          i = time_k.get(i) + 1;
        }
      }
      }

      edited_word_array_with_timestamps[0].word = edited_word_array_with_timestamps[0].word.trimStart();
      return edited_word_array_with_timestamps;
  }




}
